.marketing {
    h1 {
        font-size: 28px;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 16px;
    }

    a {
        text-decoration: none;
        font-weight: 500;
        color: #373b51;

        &:hover {
            text-decoration: underline;
            color: #5f78ff;
        }
    }
}