@font-face {
    font-family: 'Font Awesome Kit';
    font-style: normal;
    src: url('../fonts/kit-upload.woff2') format("woff2");
  } 

  .fak {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome Kit';
  }

.fa-orkawhale:before {
    content: '\e000';
  }

  .fa-orkalogo:before {
    content: '\e001';
  }

  .fa-mlogo:before {
    content: '\e002';
  }

  .fa-k8:before {
    content: '\e003';
  }

  .fa-macmini:before {
    content: '\e004';
  }

  .fa-macpro:before {
    content: '\e005';
  }

  .fa-vm-imac:before {
    content: '\e006';
  }

  .fa-vmware:before {
    content: '\e007';
  }

  .fa-anka:before {
    content: '\e008';
  }



